<template>
  <div class="detail">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" fixed 
      placeholder />
    <div class="shop-header">
      <img :src="goodsInfo.imgUrl" />
    </div>
    <div class="shop-info">
      <div class="shop-title">{{ goodsInfo.goodTitle }}</div>
      <div class="shop-subtitle">
        <div class="shop-price">
          <span class="shop-curry">¥</span>{{ goodsInfo.salePrice }}
        </div>
        <div class="shop-original">
          原价¥<span class="original">{{ goodsInfo.originalPrice }}</span>
        </div>
        <div class="shop-label">
          <p class="goodlabel">{{ goodsInfo.goodLabel }}</p>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="shop-remark">—————— 宝贝详情 ——————</div>
    <div class="shop-desc">{{ goodsInfo.description }}</div>
    <div class="shop-img">
      <div v-for="(item, index) in goodsInfo.pictures?.dataList" :key="index">
        <img :src="item.pictureUrl" />
      </div>
      <div
        v-for="(item, index) in goodsInfo.pictureDetails?.dataList"
        :key="index"
      >
        <img :src="item.pictureUrl" />
      </div>
    </div>
    <div class="empty"></div>
    <div class="fixButton">
      <van-button type="primary" :block="true" @click="gotobuy"
        >立即下单</van-button
      >
    </div>
  </div>
</template>

<script>
import { searchGoodInfo } from "@/api/product.js";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const goodsInfo = ref({});
    onMounted(() => {
      console.log(route.params, "params");
      if (route.params.id) {
        searchGoodInfo({ goodId: route.params.id }).then((res) => {
          console.log(res.data, "res");
          goodsInfo.value = res.data;
        });
        // searchGoodInfo();
      }
    });
    const onClickLeft = () => {
      router.back();
    };
    const gotobuy = () => {
      router.push({ path: `/order/buygoods/${route.params.id}` });
    };
    return { goodsInfo, onClickLeft, gotobuy };
  },
};
</script> 
<style lang="scss" scoped>
.detail {
  overflow-y: scroll;
}
img {
  width: 100%;
}
.shop-header {
  background: transparent;
  background-color: #fff;
  text-align: center;
}
.shop-info {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  background-color: #fff;
}
.shop-title {
  font-size: 1.3rem;
}
.shop-subtitle {
  display: flex;
  line-height: 1.5rem;
  margin-top: 0.5rem;
}
.shop-price {
  color: #ff9600;
  font-weight: bold;
  padding-right: 0.3rem;
  font-size: 1.3rem;
}
.shop-curry {
  font-size: 0.5rem;
  padding-right: 0.1rem;
  font-weight: normal;
}
.shop-original {
  font-size: 0.7rem;
  font-weight: normal;
  padding-right: 0.3rem;
}
.original {
  text-decoration: line-through;
}
.shop-label {
  margin-left: 2rem;
}
.shop-img {
  min-height: 300px;
}
.fixButton {
  position: fixed;
  width: 100%;
  bottom: 50px;
}
.goodlabel {
  background-color: #ff9600;
  color: #ffffff;
  border-radius: 0.3rem;
  font-size: 0.7rem;
  margin-block-start: 0em;
  margin-block-end: 0em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0rem 0.3rem;
}
.shop-desc {
  padding: 0.5rem 0.7rem;
  background-color: #fff;
  border-radius: 0.3rem;
}
.shop-remark {
  line-height: 2rem;
  text-align: center;
  color: #b2b2b2;
  font-size: 0.7rem;
}
.empty {
  height: 100px;
}
.clear {
  clear: both;
}
</style>